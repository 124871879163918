import {defineI18nConfig} from "#i18n";
import * as de from "~/utils/i18n/de.json";
import { de as $vuetify } from 'vuetify/locale'

const datetimeFormats = {
  de: {
    short: {
      day: "2-digit", month: "2-digit", year: "numeric", hour12: false,
    },
    long: {
      day: "2-digit", month: "2-digit", year: "numeric",
      hour: "2-digit", minute: "2-digit", hour12: false,
    }
  }
}

export default defineI18nConfig(() => {
  return {
    legacy: false,
    locale: "de",
    messages: {
      de: {
        ...de,
        $vuetify
      },
    },
    datetimeFormats,
    fallbackWarn: false,
    missingWarn: false
  };
});
