import revive_payload_client_3YIelIrP6T from "/build/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_YeP5JRQ0mU from "/build/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_rollup@4.24.0_vite@5.4.9_vue@3.5.13/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.js";
import vuetify_icons_xaz3Fez7yS from "/build/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_rollup@4.24.0_vite@5.4.9_vue@3.5.13/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_FMHHeUdXb8 from "/build/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vJlW5mhcjF from "/build/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_w5AKLAmZH0 from "/build/node_modules/.pnpm/@nuxtjs+supabase@1.4.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_5D1UGBX4Gj from "/build/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_eLOAVEGmWQ from "/build/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.9/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_K04jsfbwFK from "/build/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Ue7rIUf4fd from "/build/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.9/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ijLYiE9SXv from "/build/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_syQ7Zntuo8 from "/build/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_B937T4I6TS from "/build/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_rollup@4.24.0_vite@5.4.9_vue@3.5.13/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import sentry_client_y2ovp2dKeN from "/build/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_klgaqkt6bn6pzvfxdacbljteqi/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/build/.nuxt/sentry-client-config.mjs";
import auth_redirect_HwZxue2pHj from "/build/node_modules/.pnpm/@nuxtjs+supabase@1.4.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import switch_locale_path_ssr_4XlJQxy6fH from "/build/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_SaKHGJMSOE from "/build/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import vuetify_7h9QAQEssH from "/build/plugins/vuetify.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/build/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_3YIelIrP6T,
  vuetify_i18n_YeP5JRQ0mU,
  vuetify_icons_xaz3Fez7yS,
  unhead_FMHHeUdXb8,
  router_vJlW5mhcjF,
  supabase_client_w5AKLAmZH0,
  payload_client_5D1UGBX4Gj,
  navigation_repaint_client_eLOAVEGmWQ,
  check_outdated_build_client_K04jsfbwFK,
  chunk_reload_client_Ue7rIUf4fd,
  plugin_vue3_ijLYiE9SXv,
  components_plugin_KR1HBZs4kY,
  prefetch_client_syQ7Zntuo8,
  vuetify_no_client_hints_B937T4I6TS,
  sentry_client_y2ovp2dKeN,
  sentry_client_config_o34nk2sJbg,
  auth_redirect_HwZxue2pHj,
  switch_locale_path_ssr_4XlJQxy6fH,
  i18n_SaKHGJMSOE,
  vuetify_7h9QAQEssH,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]